<template>
  <div class="content">
    <Breadcrumb></Breadcrumb>

    <div class="box">
      <el-row>
        <el-col :span="19">
          <el-tabs v-model="activeName" @tab-click="$handleRoute(activeName)">
            <el-tab-pane :label="(tab.meta || {}).title || tab.name" :name="tab.name"  v-for="(tab , index) in tabList" :key="index"></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :offet="1" :span="4" style="text-align: right" v-if="powerName">
          <el-dropdown @command="handleCommand">
            <el-link>
              {{folderData.name || '文件夹'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-link>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="'folder:'+folder.id" :disabled="folder.id === folderData.id" v-for="(folder,folderKey) in folderList" :key="folderKey">
                {{ folder.name }}
              </el-dropdown-item>
              <el-dropdown-item command="create" :divided="folderList.length > 0" v-if="$isPowers([powerName+'FolderCreate'])">新建文件夹</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <router-view v-bind="{folderData,refreshMethod:()=>{getFolderList();}}"></router-view>
      <diy-dialog ref="dialog" :title="'创建文件夹'" width="450px" center v-if="powerName && $isPowers([powerName+'FolderCreate'])">
        <form-create ref="dialogForm" :label-width="'100px'" :components="[
              {name:'form-item',props:{prop:'folder_name',label:'文件夹名称',required:true}},
          ]">
        </form-create>
        <div slot="footer" style="display: flex;justify-content: space-between">
          <el-button @click="$refs.dialog.visibled(false)">取 消</el-button>
          <el-button type="primary" @click="handleCommand('save')">确 定</el-button>
        </div>
      </diy-dialog>
    </div>
  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
export default {
  name: "index",
  components: {DiyDialog},
  data() {
    return {
      activeName:'',
      powerName:'',
      activeType:'',
      folderList:[],
      folderData: {},
      tabList:[]
    };
  },
  watch:{
    powerName(v){
      if(v){
        this.$nextTick(()=>{
          this.getFolderList();
        })
      }
    },
  },
  methods: {
    routeUpdate(){
      // console.log( this.$router.getRoutes()," this.$router.getRoutes()");
      this.tabList = this.$router.getRoutes().filter(d=>(d.parent || {}).name === this.currentRouteName && (d.meta || {}).hidden !== true);
      // console.log( this.tabList," this.tabList)");
      // console.log( this.currentRouteName," this.currentRouteName)");
      this.folderData = {};
    },
    routeChange({routerViewDepth}){
      let $route = this.$route.matched[routerViewDepth + 1] || this.$route;
      this.activeName = $route.name
    },
    getFolderList(){
      this.$http.post('/xapi/com.folder/list' ,{type:this.activeType,pageSize:100}).then(({data})=>{
        this.folderList = [{id:0,name:'无目录'}].concat(data.data || []);
        let folderData = this.folderList.filter(d=>d.id+''===this.$route.query.folder_id+'')[0] || {}
        this.folderData = {...folderData}
      })
    },
    handleCommand(command){
      console.log(command,"command");
      if(command && command.indexOf('folder:') > -1){
        this.$handleRoute({folder_id:command.substr(7)} , true)
      }
      if(command === 'create'){
        this.$refs.dialogForm && this.$refs.dialogForm.reset()
        this.$refs.dialogForm && this.$refs.dialogForm.clearValidate()
        this.$refs.dialog.visibled();
      }
      if(command === 'save'){
        this.$refs.dialogForm && this.$refs.dialogForm.validate((formData , valid)=>{
          if(valid){
            this.$http.post('/xapi/com.folder/save' ,{...formData , type:this.activeType}).then((data)=>{
              this.getFolderList();
              this.$message.success(data.msg)
              this.$refs.dialog.visibled(false);
            })
          }
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep{
  .el-tabs__nav-wrap::after{
    display: none;
  }
}
</style>